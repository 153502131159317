<template>
    <div class="form-group">
        <label>{{$t('profile.language.label')}}</label>

        <multiselect v-if="languages.length"
                     v-model="language"
                     :selected="language"
                     :options="computedLanguages"
                     track-by="label"
                     label="label"
                     :close-on-select="true"
                     :show-labels="false"
                     :placeholder="$t('profile.language.choose')"
                     :selectLabel="$t('multiSelect.selectLabel')">
            <p slot="noResult">{{ $t('multiSelect.noResultsFound') }}</p>
        </multiselect>
    </div>
</template>

<style lang="scss">
    .change-password {
        .stars {
            font-size: 30px;
            font-weight: 200;
            -webkit-font-smoothing: antialiased;
            letter-spacing: 5px;
            float: left;
            display: inline-block;
            line-height: 0;
            padding: 0;
            margin: 17px 0 0;
        }

        .badge {
            cursor: pointer;
            margin-left: 15px;
        }
    }
</style>

<script>
  import Loader from '@/app/shared/components/Loader'
  import { mapActions, mapGetters } from 'vuex'

  const UserService = require('@/services/users/UserService')
  const LanguageService = require('@/services/i18n/LanguageService')

  export default {
    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),

      computedLanguages () {
        return this.languages.map(function (language) {
          return {
            label: language.name,
            value: language.id
          }
        })
      }
    },

    watch: {
      language () {
        const identity = this.identity
        const language = this.language

        if (!this.hasLoaded ||
          (language && identity.language && language.value == identity.language.id)
        ) {
          return
        }

        this.updateLanguage()
      }
    },

    data () {
      return {
        hasLoaded: false,
        languages: [],
        updating: false,
        language: null
      }
    },

    mounted () {
      this.load()
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      load () {
        LanguageService.findAll({}, (response) => {
          const body = response.data

          this.languages = body.rows

          if (this.identity.language) {
            const languages = this.computedLanguages.filter((language) => {
              return (language.value == this.identity.language.id)
            })

            this.language = (languages.length) ? languages[0] : null
          }

          this.hasLoaded = true
        })
      },

      updateLanguage () {
        this.updating = true

        UserService.put(this.identity.id, {
          language: this.language.value
        }, async () => {
          await this.reloadIdentity()
          this.updating = false
        })
      }
    },

    components: {
      Loader
    }
  }
</script>
