<template>
    <div class="profile">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <tab-navigation>
                        <ul>
                            <li>
                                <a @click="navigate('account')" :style="organizationColor('account')">
                                    {{$t('profile.yourAccount')}}
                                </a>
                            </li>

                            <li v-if="identity && identity.role === 'client-admin'" :style="organizationColor('users')">
                                <a @click="navigate('users')">
                                    {{$t('profile.users')}}
                                </a>
                            </li>

                            <li>
                                <a @click="navigate('reports')" :style="organizationColor('reports')">
                                    {{$t('profile.reports')}}
                                </a>
                            </li>

                            <li>
                                <a @click="navigate('notifications')" :style="organizationColor('notifications')">
                                    {{$t('profile.notifications')}}
                                </a>
                            </li>
                        </ul>

                        <div class="clearfix"></div>

                        <hr/>

                        <template v-if="page === 'account'">
                            <metadata/>
                        </template>

                        <template v-else-if="page === 'users'">
                            <users/>
                        </template>

                        <template v-else-if="page === 'reports'">
                            <reports/>
                        </template>

                        <template v-else-if="page === 'notifications'">
                            <notifications/>
                        </template>
                    </tab-navigation>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .profile {
        .tabbar {
            > div.card {
                box-shadow: none !important;

                > * {
                    box-shadow: none !important;
                }
            }
        }

        .headline {
            font-size: 18pt;
            margin: 0 0 30px 0;
        }
    }

    @media screen and (max-width: 768px) {
        ul {
            li {
                width: 100%;
            }
        }
    }
</style>

<script>
  import Notifications from '@/app/users/components/profile/Notifications'
  import Reports from '@/app/users/view/Reports'
  import TabNavigation from '@/app/layout/components/TabNavigation'
  import Metadata from '@/app/users/components/profile/Metadata'
  import Users from '@/app/users/components/profile/Users'
  import Card from '@/app/shared/components/Card'

  import { mapGetters } from 'vuex'

  export default {
    name: 'CustomerProfile',
    data () {
      return {
        page: 'account'
      }
    },

    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      products () {
        if (!this.customer) {
          return []
        }

        return this.customer.products
      },

      activeProducts () {
        return this.products.filter(product => {
          return product.active
        })
      },

      pageFromRoute () {
        return this.$route.params.page
      }
    },

    route: {
      canReuse: false
    },

    watch: {
      pageFromRoute () {
        this.checkRoute()
      }
    },

    mounted () {
      this.checkRoute()
    },

    methods: {
      navigate (page) {
        this.$router.push({
          name: 'frontend.settings.profile.page',
          params: {
            page: page
          }
        })

        this.checkRoute()
      },

      organizationColor (trigger) {
        return trigger === this.page ? { borderBottom: `2px solid ${$org('colors.standard.secondary.hex')}` } : null
      },

      checkRoute () {
        if (!this.pageFromRoute) {
          return
        }

        this.page = this.pageFromRoute
      }
    },

    components: {
      Card,
      Metadata,
      TabNavigation,
      Users,
      Reports,
      Notifications
    }
  }
</script>
