<template>
	<div class="role">
		{{ $t(`users.backend.role.${role}`) }}
	</div>
</template>

<style lang="scss" scoped>
.role {
	display: inline-block;
	color: #000;
	font-size: 12px;
	font-weight: 500;
	padding: 4px 6px;
	background-color: #eee;
	border-radius: 3px;
}
</style>

<script>
export default {
	props: {
		role: {
			type: String,
			required: true
		}
	}
}
</script>
