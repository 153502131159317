<template>
    <div class="edit-profile-meta-data" v-if="hasLoaded">
        <div class="row">
            <div class="col-lg-12">
                <div class="container-fluid">

                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{$t('profile.company')}}</h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <transition name="slide-fade">
                                <div class="alert alert-danger" v-if="updating == 'companyInformation' && error">
                                    {{error | capitalize}}
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'companyInformation' && error}">
                                <label for="inputCompanyName">{{$t('profile.company')}}</label>
                                <input type="text" class="form-control" id="inputCompanyName" v-model="customer.name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'companyInformation' && error}">
                                <label for="inputDomain">{{$t('profile.website')}}</label>
                                <input type="text" class="form-control" id="inputDomain" v-model="customer.domain">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'companyInformation' && error}">
                                <label for="selectIndustry">{{$t('profile.industry')}}</label>

                                <select class="form-control" id="selectIndustry" v-model="industry">
                                    <option v-for="industry in filteredIndustries" :key="industry.id" :value="industry.id">
										{{ (industry.translations || {})[$i18n.locale] || industry.title }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'companyInformation' && error}">
                                <label for="inputContactPerson">{{$t('profile.contactPerson')}}</label>
                                <input type="text" class="form-control" id="inputContactPerson" v-model="customer.contactPerson">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'companyInformation' && error}">
                                <label for="inputPhoneNumber">{{$t('profile.phone')}}</label>
                                <input type="text" class="form-control" id="inputPhoneNumber" v-model="customer.phoneNumber">
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div class="row">
                        <div class="col-lg-4">
                            <international-traffic />
                        </div>

                        <div class="col-lg-4">
                            <support-consent />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <button type="button" class="btn btn-primary btn-fill btn-save" @click="saveCompanyInformation()" :style="organizationBackgroundColor">
                                <template v-if="!updated && updating == 'companyInformation'">
                                    <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                                </template>
                                {{$t('profile.save')}}
                            </button>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{$t('profile.yourAccount')}}</h2>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <transition name="slide-fade">
                                <div class="alert alert-danger" v-if="updating == 'account' && error">
                                    {{error | capitalize}}
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'account' && error}">
                                <label for="inputEmail">{{$t('profile.email')}}</label>

                                <input type="text" class="form-control" id="inputEmail" v-model="account.email">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group" :class="{'has-error': updating == 'account' && error}">
                                <language></language>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <button type="button" class="btn btn-primary btn-fill btn-save" @click="saveAccount()" :style="organizationBackgroundColor">
                                <template v-if="!updated && updating == 'account'">
                                    <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                                </template>
                                {{$t('profile.save')}}
                            </button>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{$t('profile.changePassword')}}</h2>
                        </div>
                    </div>

                    <password></password>
                </div>

                <hr/>

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <h2>{{$t('profile.gdprHeadline')}}</h2>
                        </div>
                        <div class="col-lg-4">
                            <small class="link-explanation text-muted">{{$t('profile.gdprStandardDescription')}}</small>

                            <a class="link-button" href="https://spotonmarketing.dk/databehandling/" target="_blank" rel="noopener noreferrer">
                                <button class="btn btn-primary">
                                    {{$t('profile.gdprStandardLink')}}
                                </button>
                            </a>
                        </div>
                        <div class="col-lg-4">
                            <small class="link-explanation text-muted">{{$t('profile.gdprDownloadDescription')}}</small>

                            <a class="link-button" href="https://na3.docusign.net/Signing/?insession=1&ti=cae44e560dfb4ec68176c906c204ef32" target="_blank" rel="noopener noreferrer">
                                <button class="btn btn-primary">
                                    {{$t('profile.gdprDownloadLink')}}
                                </button>
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 account-settings" v-if="1 === 2">
                            <h5>{{$t('profile.usertab.download.headline')}}</h5>
                            <small class="text-muted explainer__text">{{$t('profile.usertab.download.description')}}</small>
                            <button class="btn btn-md btn-primary" @click="download">
                                {{$t('profile.usertab.download.cta')}}
                            </button>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 account-settings" v-if="isFreemium">
                            <h5>{{$t('profile.usertab.deleteAccount.headline')}}</h5>
                            <small class="text-muted explainer__text" v-html="$t('profile.usertab.deleteAccount.description')"></small>
                            <button class="btn btn-md btn-danger" @click="deleteAccount">
                                {{$t('profile.usertab.deleteAccount.cta')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .edit-profile-meta-data {
        h2 {
            color: #9a9a9a;
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 40px;
        }

        a.btn-link,
        button.btn-link {
            color: #e35a2a;
            font-weight: 500;
            padding: 15px 0;
            border: 0;
        }

        .account-settings {
            h5 {
                color: #9a9a9a;
            }

            margin-bottom: 20px;

            .explainer__text {
                min-height: 54px;
                display: block;
                margin-bottom: 20px;
                line-height: 18px;
                max-width: 600px;
            }
        }

        .vertical-align {
            display: flex;
            margin-top: 25px;
            height: 40px;
            align-items: center;
        }

        .btn-save {
            margin: 15px 0;
            padding: 10px 60px;

            font-weight: 500;
        }

        .link-explanation {
            display: block;
            margin-bottom: 10px;
        }

        .link-button button {
            margin-bottom: 20px;
        }
    }
</style>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import InternationalTraffic from '@/app/users/components/profile/InternationalTraffic'
  import SupportConsent from '@/app/users/components/profile/SupportConsent'
  import Password from '@/app/users/components/profile/Password'
  import Language from '@/app/users/components/profile/Language'

  import organization from '@/mixins/customer/organization'

  import * as IndustryService from '@/services/customers/IndustryService'
  import * as CustomerService from '@/services/customers/CustomerService'
  import * as UserService from '@/services/users/UserService'

  export default {
    mixins: [organization],

    data () {
      return {
        hasLoaded: false,
        industries: [],
        updating: null,
        updated: true,
        error: null
      }
    },

    computed: {
      ...mapGetters('identity', {
        account: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      industry () {
        if(!this.customer.industry) {
          return null
        }

        return this.customer.industry.id
      },

      organizationBackgroundColor () {
        return { backgroundColor: $org('colors.standard.button.outlined') }
      },

      phoneNumbers () {
        const phoneNumbers = this.customer.phoneNumbers

        if (!phoneNumbers || !phoneNumbers.length) {
          return null
        }

        const slicedNumbers = phoneNumbers.map(number => number.phoneNumber)

        return slicedNumbers.slice(0, 3).join(', ')
      },

      filteredIndustries () {
        return this.industries.filter(industry => {
          return industry.visible
        })
      }
    },

    mounted () {
      this.load()
    },


    methods: {
      ...mapActions('identity', {
        loadIdentity: 'loadIdentity'
      }),

      load () {
        IndustryService.findAll({
          sort: [{
            key: 'highlighted',
            direction: 'DESC'
          }, {
            key: 'title',
            direction: 'ASC'
          }]
        }, response => {
          const data = response.data

          this.industries = data.rows
          this.hasLoaded = true
        })
      },

      async saveCompanyInformation () {
        this.updating = 'companyInformation'
        this.updated = false
        this.error = null

        try{
          await this.$store.dispatch('customer/saveCustomer', this.customer)
        }catch (error) {
          this.error = error
        }

        this.updated = true

/*        CustomerService.put(customer.id, {
          name: customer.name,
          domain: customer.domain,
          phoneNumber: customer.phoneNumber,
          contactPerson: customer.contactPerson,
          industry: this.industry
        }, response => {
          this.loadIdentity({
            cb: () => {
              this.updated = true
            }
          })
        }, error => {
          const response = error.response
          const data = response.data

          if (response.status === 422) {
            const errors = data.errors[0].title
            const keys = Object.keys(errors)

            this.error = data.errors[0].title[keys[0]][0]
          }

          this.updated = true
        })*/
      },

      deleteAccount () {
        this.$swal({
          text: $t('profile.usertab.deleteAccount.modal.areYouSure'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: $t('profile.usertab.deleteAccount.modal.confirm'),
          cancelButtonText: $t('profile.usertab.deleteAccount.modal.regret')
        }).then(() => {
          CustomerService.requestDelete(this.customer.id, response => {
            this.$swal({
              text: $t('profile.usertab.deleteAccount.modal.success'),
              type: 'success',
              confirmButtonText: $t('profile.usertab.deleteAccount.modal.okay'),
            }).then(() => {
              this.$router.push({ name: 'logout' })
            })
          })
        })
      },

      saveAccount () {
        this.updating = 'account'
        this.updated = false
        this.error = null

        const account = this.account

        UserService.put(account.id, {
          email: account.email
        }, async (response) => {
          await this.loadIdentity()
          this.updated = true
        }, error => {
          const response = error.response
          const data = response.data

          if (response.status === 422) {
            const errors = data.errors[0].title
            const keys = Object.keys(errors)

            this.error = data.errors[0].title[keys[0]][0]
          }

          this.updated = true
        })
      }
    },

    components: {
      InternationalTraffic,
      Password,
      Language,
      SupportConsent
    }
  }
</script>
