<template>
    <div class="international-traffic form-group" :class="$org('slug')">
        <label>{{$t('profile.internationalTraffic.label')}}</label><br/>

        <small class="text-muted">
            {{$t('profile.internationalTraffic.description')}}
        </small>

        <div class="clearfix"></div>

        <div class="action" v-if="hasLoaded">
            <label class="switch switch-sm">
                <input type="checkbox"
                       @change="update"
                       :value="includeInternationalTraffic"
                       :checked="includeInternationalTraffic">
                <div class="slider round" :class="{checked: includeInternationalTraffic}"/>
            </label>

            <div class="clearfix"></div>
        </div>

        <loader :inline="true" v-else/>
    </div>
</template>

<style lang="scss" scoped>
    @import "~@/assets/scss/variables/colors";
    .international-traffic {
        &.sgme .slider.checked{
            background: $sgme-primary;
        }

        &.spoton-marketing .slider.checked{
            background: $sol-primary;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;

            &.switch-sm {
                width: 50px;
                height: 25px;

                .slider:before {
                    width: 17px;
                    height: 17px;
                }
            }

            &.switch-xs {
                width: 46px;
                height: 20px;

                .slider:before {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        label {
            margin-bottom: 0;
        }

        .action {
            margin-top: 10px;
        }

        /* Hide default HTML checkbox */
        .switch input {
            display: none;
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider {
            background-color: #2196F3;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }


</style>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: mapGetters('customer', ['includeInternationalTraffic']),

    data () {
      return {
        hasLoaded: true
      }
    },

    methods: {
      ...mapActions('identity', ['loadIdentity']),

      async update () {
        this.hasLoaded = false
        try {
          await this.$store.dispatch('customer/includeInternationalTraffic', !this.includeInternationalTraffic)
          this.hasLoaded = true
        } catch (e) {
          this.hasLoaded = true
          throw e
        }
      }
    }
  }
</script>
