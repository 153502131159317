<template>
    <div class="notifications">
        <loader v-if="!notificationTypes.length"></loader>

        <div class="row" v-else>
            <div class="col col-sm-6" v-for="(notificationType, index) in notificationTypes">
                <div class="action" @click="toggle(index)">

                    <template v-if="!isChanging(index)">
                        <span class="fa fa-check-square-o fa-fw" v-if="subscribed(index)"></span>
                        <span class="fa fa-square-o fa-fw" v-else></span>
                    </template>

                    <loader :inline="true" v-else></loader>
                </div>

                {{notificationType.title}}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .notifications {
        .col {
            margin-top: 15px;
            .action {
                cursor: pointer;
                width: 25px;
            }
        }
    }
</style>

<script>
import Loader from '@/app/shared/components/Loader'
import MixpanelService from '@/services/mixpanel/MixpanelService'

const ClientService = require('@/services/users/ClientService')
const NotificationService = require('@/services/notifications/NotificationService')

export default {
    props: {
        notificationTypes: Array,
        user: Object
    },

    data() {
        return {
            changing: [],
            localUser: null
        }
    },

    computed: {
        latestUser() {
            return this.localUser ? this.localUser : this.user
        }
    },

    methods: {
        toggle(index) {
            if (this.isChanging(index)) {
                return
            }

            this.changing.push(index)

            const type = this.notificationTypes[index]

            if (this.subscribed(index)) {
                // Un-subscribe

                NotificationService.unsubscribe(this.latestUser.id, type.id, () => {
                    this.reload(index)
                })

                MixpanelService.track('Profile - Notifications - Un-subscribe', {type: type.slug})

                return;
            }

            // Subscribe

            const data = {
                typeId: type.id
            };

            NotificationService.subscribe(this.latestUser.id, data, () => {
                this.reload(index)
            })

            MixpanelService.track('Profile - Notifications - Subscribe', {type: type.slug})
        },

        subscribed(index) {
            const type = this.notificationTypes[index]
            const subscriptions = this.latestUser.notificationSubscriptions

            for(let i = 0; i < subscriptions.length; i++) {
                const subscription = subscriptions[i]

                if (subscription.id === type.id) {
                    return true
                }
            }

            return false
        },

        reload(index) {
            ClientService.find(this.latestUser.id, {
                includes: ['notificationSubscriptions']
            }, (response) => {
                this.localUser = response.data

                this.changing.splice(this.changing.indexOf(index), 1)
            })
        },

        isChanging(index) {
            return (this.changing.indexOf(index) !== -1)
        }
    },

    components: {
        Loader
    }
}
</script>
