<template>
    <div class="user-list">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <card icon="pe-7s-users" :headline="$t('users.backend.overview.headline')" :description="$t('users.backend.overview.description')">

                        <search class="search" :options="filters" @filters-updated="updateFilters" />

                        <hr />

                        <!-- Users -->

						<div class="list-group" style="margin: -20px -30px;">
							<div class="list-dummy" v-for="n in limit" v-if="!hasLoaded">
								<span class="mock">company name</span><br />
								<small class="mock">X abc.com&nbsp;&nbsp;Y abc def ghijkl</small>

								<div class="clearfix"></div>
							</div>

							<template v-if="users.length">
								<div class="user-row" v-for="user in users" :key="user.id">
									<user-list-item :user="user" />
								</div>
							</template>
						</div>

                        <hr />

                        <div class="pull-right">
                            <pagination :total="total" :limit="limit" :page="page" v-on:paginate="paginate" />
                        </div>

                        <div class="clearfix"></div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .search {
        margin-bottom: 30px;
    }

    .user-row {
        &:nth-child(even) {
            background: #f9f9f9;
        }
    }

    .user-list {
        .controls {
            margin-top: 18px;
            .layout {
                .setting {
                    cursor: pointer;
                    color: #ccc;
                    font-size: 13pt;

                    &.active {
                        color: #4d91ff;
                    }
                }
            }
        }

        .box-layout {
            .box-dummy {
                h1 {
                    display: block;
                    margin: -10px auto;
                }

                .bottom {
                    float: left;
                    margin: -7px;
                }

                .content {
                    height: 197px;
                }
            }
        }

        .list-dummy {
            line-height: 29px;
            padding: 20px 45px;
            border-bottom: 1px solid #eee;

            &:last-child {
                hr {
                    display: none;
                }
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Card from '@/app/shared/components/Card'
    import Search from '@/app/shared/global/search/Search'
    import Pagination from '@/app/layout/components/paginator/Pagination'

    import UserListItem from './components/UserListItem'

    const UserService = require('@/services/users/UserService')
    const FilterService = require('@/services/filters/FilterService')

    import {mapGetters} from 'vuex'
	import ClientService from "@/services/_app/users/ClientService";

    export default {
        data() {
            return {
                hasLoaded: false,
                users: [],
                total: 0,
                filtersLoaded: 0,
                numberOfFilters: 0,
                page: 1,
                filters: {
                    query: null,
                    filters: []
                }
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            limit() {
                return 20
            }
        },

        watch: {
            limit() {
                this.page = 1
                if (this.filtersLoaded === this.numberOfFilters) {
                    this.load()
                }
            },

            filters() {
                if (this.filtersLoaded === this.numberOfFilters) {
                    this.load()
                }
            }
        },

        methods: {
            load() {
                this.hasLoaded = false
                this.users = []

                const options = {
                    sort: [{
                        key: 'created',
                        direction: 'DESC'
                    }],

                    includes: [],

                    page: this.page,
                    limit: this.limit,

                    filter_groups: FilterService.toRequest(this.filters, [
                        'email'
                    ])
                }

				ClientService.findAll(options)
					.then(body => {
						this.users = body.rows
						this.total = body.total
						this.hasLoaded = true
					})
					.catch(error => {
						this.hasLoaded = true
						eventHub.$emit('ajax-error', error.response)
					})
            },

            paginate(page) {
                this.page = page
                this.load()
            },

            updateFilters(filters) {
                this.filters = filters
                this.page = 1
            }
        },

        components: {
            Loader,
            Card,
            Pagination,
			UserListItem,
            Search
        }
    }
</script>
