<template>
    <div class="user-view">
        <loader v-if="!hasLoaded"></loader>

        <!-- User not found -->
        <div class="container-fluid" v-if="hasLoaded">
            <div class="row">
                <template v-if="!user">
                    <div class="col-md-12">
                        <div class="alert alert-danger text-center">
                            {{$t('users.backend.view.notFound')}}
                        </div>
                    </div>
                </template>

                <form @submit.prevent="save" v-else>
                    <!-- Info -->
                    <div class="col-xxl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <card icon="pe-7s-user" :headline="user.email">
							<ul class="meta mt-3">
								<li>
									<strong style="margin-bottom: 5px;">Role: </strong>
									<user-role :role="user.role" />
								</li>
								<li>
									<strong>Last login: </strong>
									<span v-if="!! user.lastLogin">{{ user.lastLogin.date | moment('LLL')}}</span>
									<span v-else>Never</span>
								</li>
								<li>
									<strong>Created at: </strong>
									<span>{{ user.created.date | moment('LLL')}}</span>
								</li>
							</ul>

							<button type="button"
								    @click="impersonate"
								    class="impersonate">
								<span>Impersonate</span>
							</button>

							<button type="submit"
											@click="save"
											class="save">
								<i v-if="saving"
								   class="fa fa-spinner fa-spin fa-fw" />
								<span v-else>Save</span>
							</button>
                        </card>
                    </div>

                    <!-- Pages -->
                    <div class="col-xxl-6 col-lg-8 col-md-12 col-sm-12 col-xs-12" v-if="identity.role !== 'employee-restricted'">
												<card>
													<div class="form-group" :class="{'has-error': !! errors['email']}">
														<label for="email">E-mail</label>
														<input type="text" v-model="email" id="email" class="form-control">
														<span v-if="!! errors['email']" class="error">{{ errors['email'][0] }}</span>
													</div>

													<div class="form-group" :class="{'has-error': !! errors['role']}">
														<label for="role">Role</label>

														<select v-model="role"
																    id="role"
																    class="form-control">
															<option value="client">Client</option>
															<option value="client-admin">Client admin</option>
															<option value="client-user">Client user</option>
														</select>
														<span v-if="!! errors['role']" class="error">{{ errors['role'][0] }}</span>
													</div>

													<div class="form-group">
														<label>Customers</label>

														<div class="customers">
															<div v-for="id in customerIds"
																   :key="`customer-${id}`"
																   class="customer">
																<div class="meta">
																	<div class="client-category" :style="categoryStyle(customers[id].clientCategory)">
																		<span>{{customers[id].clientCategory ? customers[id].clientCategory : '-'}}</span>
																	</div>

																	<div class="name">
																		<strong>{{ customers[id].name }}</strong>
																		<span>{{ customers[id].domain }}</span>
																	</div>
																</div>

																<div class="actions">
																	<button type="button"
																					@click="removeCustomer(id)">
																		Remove
																	</button>
																</div>
															</div>
														</div>

														<hr />

														<label for="query">Add new customers</label>

														<input placeholder="Search"
															   type="text"
															   v-model="query"
															   id="query"
															   class="form-control"
														/>

														<div v-if="searching"
															   class="text-center p-5">
															<i class="fa fa-spinner fa-spin fa-fw" />
														</div>

														<div class="customers">
															<div v-for="suggestion in suggestions"
																 	 :key="`suggestion-${suggestion.id}`"
																   class="customer">
																<div class="meta">
																	<div class="client-category"
																		 :style="categoryStyle(suggestion.clientCategory)">
																		<span>{{suggestion.clientCategory ? suggestion.clientCategory : '-'}}</span>
																	</div>

																	<div class="name">
																		<strong>{{ suggestion.name }}</strong>
																		<span>{{ suggestion.domain }}</span>
																	</div>
																</div>

																<div class="actions">
																	<button v-if="! customerIds.includes(suggestion.id)"
																			    @click="addCustomer(suggestion)"
																			    type="button"
																			    class="add">
																		Add
																	</button>

																	<button v-else
																			    @click="removeCustomer(suggestion.id)"
																			    type="button">
																		Remove
																	</button>
																</div>
															</div>
														</div>
													</div>
												</card>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    @media screen and (min-width: 1200px) and (max-width: 1530px) {
        .col-xxl-6 {
            width: 50% !important;
        }
    }

    .user-view {
        .info {
            float: left;
            margin-top: 10px;

            .icon {
                color: #3587ff;
                font-size: 30pt;
                margin-right: 20px;
            }

            h1 {
                font-size: 13pt;
                margin: 0;
                padding: 0;
                margin-top: 2px;
            }

            .domain {
                color: #898989;
            }
        }

        .actions {
            float: right;
            text-align: right;

            .synchronized {
                display: block;
                color: #aeaeae;
                font-size: 9pt;
                margin-top: -10px;
            }

            .btn-success {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 9pt;
                margin-top: 15px;
            }
        }
    }

	.customers {
		margin-top: 10px;

		.customer {
			border-top: 1px solid #eee;
			padding: 15px 0;

			display: flex;
			align-items: center;
			justify-content: space-between;

			.meta {
				display: flex;
				align-items: center;

				.client-category {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 60px;
					height: 60px;
					border-radius: 0;
					margin-right: 15px;
				}

				.name {
					strong {
						display: block;
						font-size: 15px;
						font-weight: 500;
					}

					span {
						color: #5e5e5e;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}

			.actions {
				button {
					color: #000;
					background-color: #eeeeee;
					font-size: 13px;
					font-weight: 500;
					padding: 12px 18px;
					border-radius: 4px;
					border: 0;
					box-shadow: none;
					outline: 0;

					&.add {
						color: #fff;
						background-color: #464646;
					}
				}
			}
		}
	}

	ul.meta {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 13px;

		li {
			padding: 15px 0;
			border-bottom: 1px solid #eee;

			&:last-child {
				padding-bottom: 0;
				border-bottom: 0;
			}

			strong {
				display: block;
				font-weight: 600;
			}
		}
	}

	.impersonate {
		width: 100%;
		color: #000 !important;
		background-color: #e8e8e8;
		font-size: 13px;
		font-weight: 500;
		padding: 12px 18px;
		border-radius: 4px !important;
		border: 0;
		box-shadow: none;
		outline: 0;
		margin-top: 25px;
	}

	.save {
		width: 100%;
		color: #fff !important;
		background-color: #3990de;
		font-size: 13px;
		font-weight: 500;
		padding: 12px 18px;
		border-radius: 4px !important;
		border: 0;
		box-shadow: none;
		outline: 0;
		margin-top: 10px;
	}
</style>

<script>
import Loader from '@/app/shared/components/Loader'
import Card from '@/app/shared/components/Card'

import {mapActions, mapGetters} from 'vuex'
import ClientService from "@/services/_app/users/ClientService";
import UserRole from "@/app/users/components/UserRole";

const UserService = require('@/services/users/UserService')

import { debounce } from 'lodash'
import CustomerService from "@/services/_app/customers/CustomerService";

export default {
	name: 'UserView',

	data () {
		return {
			hasLoaded: false,
			user: null,

			query: '',

			email: '',
			role: '',

			customerIds: [],

			customers: [],

			suggestions: [],
			searching: false,
			searchDebounce: null,

			saving: false,

			errors: {}
		}
	},

	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),

		id () {
			return this.$route.params.id
		}
	},

	watch: {
		id () {
			this.load()
		},

		user() {
			const user = this.user

			this.email = ''
			this.role = ''
			this.customers = []

			if (! user) {
				return
			}

			this.email = user.email
			this.role = user.role
			this.customerIds = user.customers.map(c => c.id)
			this.customers = user.customers.reduce(
				(carry, customer) => ({
					...carry,
					[customer.id]: customer
				}),
				{}
			)
		},

		query() {
			this.searchDebounce(this.query)
			this.searching = true
		}
	},

	beforeMount() {
		this.searchDebounce = debounce(this.search, 500)
	},

	mounted () {
		this.load()
	},

	methods: {
		...mapActions("ghost", ["logInAsGhost"]),

		load () {
			this.hasLoaded = false
			this.user = null

			const data = {
				includes: [
					'customerGroups',
					'customers',
				]
			}

			return new Promise((resolve, reject) => {
				ClientService.find(this.id, data)
					.then(body => {
						this.user = body
						this.hasLoaded = true
						resolve()
					})
					.catch(() => {
						this.hasLoaded = true
						reject()
					})
			})
		},

		search(query) {
			this.suggestions = []
			this.searching = true

			if (String(query || '').length === 0) {
				this.searching = false
				return
			}

			CustomerService.findAll({
				filter_groups: [{
					or: true,

					limit: 10,

					filters: [
						{
							key: 'name',
							operator: 'ct',
							value: query
						},
						{
							key: 'domain',
							operator: 'ct',
							value: query
						}
					]
				}]
			}).then(body => {
				this.suggestions = body.rows
				this.searching = false
			}).catch(() => {
				this.searching = false
			})
		},

		categoryStyle(clientCategory) {
			switch (clientCategory) {
				case 'A+':
					return 'background: #007c65;'
				case 'A':
					return 'background: #00af8e;'
				case 'B':
					return 'background: #fac725;'
				case 'C':
					return 'background: #9c61ff;'
				default:
					return 'background: #cccccc;'
			}
		},

		addCustomer(customer) {
			this.$set(this.customers, customer.id, {...customer})
			this.customerIds.push(customer.id)
		},

		removeCustomer(id) {
			const index = this.customerIds.indexOf(id)
			this.customerIds.splice(index, 1)
		},

		async impersonate() {
			await this.logInAsGhost({
				email: this.user.email
			});

			this.$router.push({ name: "frontend.overview" })
		},

		save() {
			if (this.saving) {
				return
			}

			const email = this.email
			const role = this.role
			const customer_ids = this.customerIds

			this.saving = true
			this.errors = {}

			ClientService.put(this.id, {
				email,
				role,
				customer_ids
			}).then(() => {
				this.load().then(() => {
					this.saving = false
				})
			}).catch(error => {
				const response = error.response

				const { errors } = response.data

				this.errors = errors.reduce(
					(carry, item) => ({
						...carry,
						...item.title
					}),
					{}
				)
				this.saving = false
			})
		}
	},

	components: {
		UserRole,
		Loader,
		Card
	}
}
</script>
