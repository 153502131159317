<template>
    <div class="reports">
        <template v-if="hasLoaded">
            <div class="row">
                <div class="col-lg-4">
                    <card>
                        <div class="headline-container g-clearfix">
                            <h4 class="headline-container__periodic">
                                {{$t('downloadReports.monthly')}}
                            </h4>

                            <small class="text-muted">{{$t('downloadReports.description')}}</small><br>

                            <small v-if="reports.length < 1" class="text-muted">
                                {{$t('downloadReports.none')}}
                            </small>

                            <select class="form-control year-select pull-right" v-model="selectedYear" v-show="years.length > 1">
                                <option :value="year" v-for="year in years">{{year}}</option>
                            </select>
                        </div>

                        <hr>

                        <div class="content g-clearfix">
                            <ul>
                                <li v-for="(report, index) in selectedReports" @click="preview(report.id)">
                                    <div class="pull-right download">
                                        <i class="fa fa-download fa-fw" @click="download(report.id)" />
                                    </div>

                                    <div>
                                        <div class="pull-left">
                                            <i class="fa fa-file-pdf-o fa-fw pdf"/>
                                        </div>

                                        <div class="pull-left">
                                            #{{index + 1}} {{$t('downloadReports.report')}}<br />
                                            <small class="text-muted">
                                                {{report.from.date | moment("D MMMM")}} -
                                                {{report.to.date | moment("D MMMM 'YY")}}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>
                                </li>
                            </ul>
                        </div>
                    </card>
                </div>

                <div class="col-lg-8" v-if="selectedReport">
                    <card>
                        <div class="headline-container g-clearfix">
                            <span class="pull-left headline-container__periodic">
                                {{$t('downloadReports.report')}},
                                <small class="text-muted">
                                    {{selectedReport.from.date | moment("D MMMM")}} -
                                    {{selectedReport.to.date | moment("D MMMM 'YY")}}
                                </small>
                            </span>

                            <div class="pull-right" style="margin-bottom: 20px;">
                                <button class="btn btn-primary btn-sm" @click="download(selectedReport.id)">
                                    Download&nbsp;
                                    <span class="fa fa-download fa-fw"></span>
                                </button>
                            </div>
                        </div>

                        <hr>

                        <div class="content g-clearfix">
                            <div v-html="selectedReport.content" id="reportContent"></div>
                        </div>
                    </card>
                </div>
            </div>
        </template>

        <div class="text-center" v-else>
            <span class="fa fa-spin fa-spinner fa-fw"></span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_custom.scss';
    @import '~@/assets/scss/_vars.scss';
    .reports {
        .year-select {
            width: auto;
            min-width: 100px;
        }

        .headline-container {
            margin-bottom: 20px;

            .headline-container__periodic {
                font-size: 16px;
                font-weight: 400;
                margin-top: 0;
            }

            .headline-container__year {
                font-size: 12px;
                font-weight: 500;
                background: #4096ee;
                color: #fff;
                padding: 5px 10px;
                border-radius: 3px;
            }
        }

        ul {
            display: block;
            padding: 10px 0 0 0;

            li {
                padding-bottom: 20px;
                list-style-type: none;
                width: 100%;
                float: left;
                cursor: pointer;

                &:hover {
                    .download {
                        color: #282828;
                        margin-right: 5px;
                    }
                }

                .download {
                    padding-top: 5px;
                    color: #888;
                    transition: all 0.2s ease;

                    i {
                        margin-left: 10px;
                    }
                }

                i {
                    &.pdf {
                        font-size: 16px;
                        color: $focusAlert;
                        line-height:20px;
                        float: left;
                        width: 30px;
                        text-align: center;
                    }

                    &.download {
                        padding-top: 5px;
                        color: #888;
                        transition: all 0.2s ease;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1310px) {
        .col-lg-4 {
            width: 100%;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'
    import * as PerformanceReportService from '@/services/performance-report/PerformanceReportService'

    import {mapGetters} from 'vuex'

    const moment = require('moment')

    export default {
        data() {
            return {
                reports: [],
                hasLoaded: false,
                selectedYear: null,
                selectedReport: null
            }
        },

        watch: {
            reports() {
                if (!this.years.length) {
                    return
                }

                this.selectedYear = this.years[0]
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          }),

            years() {
                const years = []

                this.reports.forEach(report => {
                    const year = moment(report.from.date).format('Y')

                    if (years.indexOf(year) !== -1) {
                        return
                    }

                    years.push(year)
                })

                return years
            },

            selectedReports() {
                return this.reports.filter(report => {
                    return moment(report.from.date).format('Y') === this.selectedYear
                })
            }
        },

        mounted() {
            this.load()
        },

        methods: {
            load() {
                PerformanceReportService.findAll({
                    sort: [{
                        key: 'from',
                        direction: 'DESC'
                    }],

                    includes: ['batch'],

                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'state',
                                    operator: 'in',
                                    value: ['sent', 'deactivated']
                                }
                            ]
                        }
                    ]
                }, result => {
                    const data = result.data

                    this.reports = data.rows
                    this.hasLoaded = true
                })
            },

            preview(id) {
                this.selectedReport = null

                const reports = this.reports.filter(report => {
                    return report.id === id
                })

                if (!reports.length) {
                    return
                }

                this.selectedReport = reports[0]
            },

            async download(id) {
                // Find report
                const reports = this.reports.filter(report => {
                    return report.id === id
                })

                if (!reports.length) {
                    return
                }

                const report = reports[0]

                PerformanceReportService.pdf(report.id, {}, result => {
                    const data = result.data

                    let link = document.createElement('a')
                    link.href = data.pdf
                    link.download = 'report.pdf'

                    link.click()
                })
            }
        },

        components: {
            Card
        }
    }
</script>
