<template>
    <div class="element">
        <loader v-if="!hasLoaded"></loader>

        <template v-if="hasLoaded">
            <div class="row">
                <div class="clearfix"></div>

                <div class="col-md-12 text-center " v-if="!users.length">
                    Denne kunde har endnu ingen brugere tilknyttet<br />
                    <br />

                    <button class="btn btn-primary btn-round" @click="create">
                        Klik her for at oprette en bruger
                    </button>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 account-settings g-row--no-padding">
                    <span class="headline">{{$t('profile.usertab.overview.headline')}}</span>
                    <hr>

                    <button class="create-user-btn btn btn-primary" v-if="users.length" @click="create">
                        {{$t('profile.usertab.overview.cta')}}
                    </button>
                </div>

                <div class="col-lg-6 col-md-6 users" v-for="(user, index) in users" :key="user">
                    <card>
                        <div class="pull-left info">
                            <span class="icon pe-7s-user pull-left">
                                <span class="fa fa-star fa-fw star" v-if="user.role === 'client-admin'"></span>
                            </span>

                            <div class="pull-left">
                                <span class="text-muted">E-mail</span><br />
                                {{user.email}}
                            </div>

                            <div class="clearfix"></div>
                        </div>

                        <div class="pull-right action hidden-xs" @click="remove(index)" v-if="user.id !== identity.id && user.role !== 'client-admin'">
                            <span class="icon pe-7s-trash"></span>
                        </div>
                    </card>
                </div>
            </div>
        </template>

        <modal ref="create-user" title="Opret en ny bruger">
            <user-create v-on:created="created" :customerId="customer.id"></user-create>
        </modal>
    </div>
</template>

<style lang="scss" scoped>
    .element {
        padding: 15px;
        .headline {
            display: block;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .create-user-btn {
            margin-bottom: 15px;
        }

        a {
            color: #747474;
            font-size: 10pt;
            text-decoration: underline;
            margin-left: 5px;
        }

        .info {
            margin-bottom: 30px;

            .icon {
                position: relative;
                font-size: 32pt;
                margin-right: 10px;

                .star {
                    color: #ffd544;
                    position: absolute;
                    font-size: 20px;
                    bottom: -2px;
                    right: -2px;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: darken(#ffd544, 50%);
                }
            }
        }

        .users {
            > .card {
                overflow: hidden;
            }
        }

        .action {
            .icon {
                cursor: pointer;
                color: #747474;
                font-size: 20pt;
                margin: 8px 0;
            }
        }

        .content {
            padding-bottom: 20px;

            .headline {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
</style>

<script>
    import Loader from '@/app/shared/components/Loader'
    import Card from '@/app/shared/components/Card'
    import Modal from '@/app/layout/components/Modal'
    import UserCreate from '@/app/users/components/Create'
    import UserNotifications from '@/app/users/components/Notifications'

    const ClientService = require('@/services/users/ClientService')
    const NotificationTypeService = require('@/services/notifications/TypeService')

    import {mapGetters} from 'vuex'

    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [organization],

        data() {
            return {
                hasLoaded: false,
                users: [],
                notificationTypes: [],
                sendingMailTo: null
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),
          ...mapGetters('customer', {
            customer: 'getCustomer'
          })
        },

        mounted() {
            this.load()
            this.loadNotificationTypes()
        },

        methods: {
            load() {
                this.hasLoaded = false
                this.users = []

                ClientService.findAll({
                    includes: ['notificationSubscriptions']
                }, (response) => {
                    const body = response.data

                    this.users = body.rows
                    this.hasLoaded = true
                });
            },

            loadNotificationTypes() {
                NotificationTypeService.findAll({}, (response) => {
                    const body = response.data

                    this.notificationTypes = body.rows
                });
            },

            remove(index) {
                const user = this.users[index]
                const deletedUserId = user.id

                this.$swal({
                    text: $t('users.backend.delete.areYouSure'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: $t('users.backend.delete.confirm'),
                    cancelButtonText: $t('users.backend.delete.regret')
                }).then(() => {
                    ClientService.remove(user.id, (response) => {
                        this.users = this.users.filter(function (user) {
                            return (user.id !== deletedUserId)
                        })
                    })
                })
            },

            create() {
                if (!this.$refs['create-user'].$el) {
                    setTimeout(this.create, 200)
                }

                jQuery(this.$refs['create-user'].$el).modal('show')
            },

            created() {
                if (!this.$refs['create-user'].$el) {
                    setTimeout(this.created, 200)
                }

                jQuery(this.$refs['create-user'].$el).modal('hide')

                this.load();
            },

            download() {
            }
        },
        components: {
            Loader,
            Card,
            Modal,
            UserCreate,
            UserNotifications
        }
    }
</script>
