<template>
    <div class="support-consent form-group">
        <label>{{$t('profile.supportConsent.label')}}</label><br/>

        <small class="text-muted">
            {{$t('profile.supportConsent.description')}}
        </small>

        <div class="clearfix"></div>

        <div class="action" v-if="hasLoaded">
            <label class="switch switch-sm">
                <input type="checkbox" v-model="supportConsent" :checked="supportConsent">
                <div class="slider round" :style="organizationColor"></div>
            </label>

            <div class="clearfix"></div>
        </div>

        <loader :inline="true" v-else></loader>
    </div>
</template>

<style lang="scss" scoped>
    .support-consent {
        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;

            &.switch-sm {
                width: 50px;
                height: 25px;

                .slider:before {
                    width: 17px;
                    height: 17px;
                }
            }

            &.switch-xs {
                width: 46px;
                height: 20px;

                .slider:before {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        label {
            margin-bottom: 0;
        }

        .action {
            margin-top: 10px;
        }

        /* Hide default HTML checkbox */
        .switch input {
            display: none;
        }

        /* The slider */
        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider {
            background-color: #2196F3;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }
</style>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Loader from '@/app/shared/components/Loader'

  const CustomerService = require('@/services/customers/CustomerService')

  export default {
    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      organizationColor () {
        return this.supportConsent ? { backgroundColor: $org('colors.standard.button.outlined') } : null
      }
    },

    data () {
      return {
        hasLoaded: true,
        supportConsent: true
      }
    },

    watch: {
      supportConsent () {
        if (this.supportConsent === this.customer.supportConsent) {
          return
        }

        this.update()
      }
    },

    mounted () {
      this.supportConsent = this.customer.supportConsent
    },

    methods: {
      ...mapActions('identity', {
        reloadIdentity: 'loadIdentity'
      }),

      update () {
        this.hasLoaded = false

        CustomerService.put(this.customer.id, {
          supportConsent: this.supportConsent
        }, async (response) => {
          await this.reloadIdentity()
          this.hasLoaded = true
        })
      }
    }
  }
</script>
