<template>
    <div class="notifications">
        <div class="row" v-if="!hasLoaded">
            <div class="col-lg-12" style="height: 120px; position: relative;">
                <logo-loader />
            </div>
        </div>

        <div class="row" v-else>
            <span class="headline">{{$t('profile.notificationCenter.headline')}}</span>
            <small>{{$t('profile.notificationCenter.description')}}</small>

            <template v-if="identity.role === 'client-admin' && accessibleUsers.length > 1">
                <h6 style="margin-top: 20px;">{{$t('profile.notificationCenter.user')}}</h6>
                <select class="form-control selector" v-model="selectedUserId">
                    <option v-for="user in accessibleUsers" :value="user.id">{{user.email}}</option>
                </select>

                <span class="information-label" v-show="identity.id !== selectedUserId">
                    <i class="fa fa-warning" />{{$t('profile.notificationCenter.adminRights')}}
                </span>
            </template>

            <hr />

            <div class="col-lg-12" style="margin-bottom: 30px;">
                <div class="col-custom-row">
                    <div class="col-custom-4-12"
                         style="padding: 5px;"
                         v-for="group in notificationGroups">

                        <ul class="group-list">
                            <li class="notification-type" @click="toggleGroup(selectedUser, group)">
                                <input type="checkbox" class="clickable" :checked="hasAllNotificationsInGroup(selectedUser, group)" />
                                {{$t(`profile.notificationCenter.${group.slug}.title`)}}
                            </li>

                            <li>
                                <ul>
                                    <li class="notification-type" v-for="type in group.types" @click="toggleNotification(selectedUser, type)">
                                        <input type="checkbox" class="clickable" :checked="subscriptions[selectedUserId].includes(type.id)" />
                                        <span v-html="$t(`profile.notificationCenter.${group.slug}.${type.slug}`)"/>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .notifications {
        padding: 15px;
        .headline {
            display: block;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .user {
            margin-bottom: 10px;
            font-weight: 500;
            text-transform: lowercase;
        }
    }

    .selector {
        margin-top: 5px;
        width: auto;
    }

    .information-label {
        background: $primaryWarning;
        color: $tertiaryWarning;
        padding: 8px 16px;
        border-radius: 3px;
        margin-top: 10px;
        display: inline-block;
        i {
            margin-right: 5px;
        }
    }

    .group-list {
        .no-indent {
            padding: 0;
        }

        &:not(.no-indent) {
            > li:first-child {
                background: #f0f0f0;
            }
        }
    }

    .notification-type {
        padding: 5px 10px;
        border-radius: 3px;
        background: #f9f9f9;
        margin-bottom: 5px;
        &:hover {
            span.plus {
                margin-right: 5px;
            }
        }
        span.plus {
            transition: all 0.8s ease;
            margin-top: 3px;
            float: right;
        }
    }

    ul {
        width: 100%;
        list-style-type: none;
        padding-left: 20px;
        li {
            cursor: pointer;
            font-size: 12px;
            .clickable {
                margin-right: 10px;
                display: inline-block;
            }
        }
    }
</style>

<script>
    import MixpanelService from '@/services/mixpanel/MixpanelService'
    import LogoLoader from '@/app/shared/components/LogoLoader'

    const ClientService = require('@/services/users/ClientService')
    const NotificationService = require('@/services/notifications/NotificationService')
    import NotificationGroupService from '@/services/notifications/NotificationGroupService'

    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                users: [],
                selectedUserId: null,
                subscriptions: {},
                notificationGroups: []
            }
        },

        computed: {
          ...mapGetters('identity', {
            identity: 'getIdentity'
          }),

            hasLoaded() {
                return this.users.length && Object.keys(this.subscriptions).length
            },

            selectedUser() {
                return this.accessibleUsers.find((user) => {
                    return user.id === this.selectedUserId
                })
            },

            isClientAdmin() {
                const identity = this.identity

                return identity && identity.role === 'client-admin'
            },

            accessibleUsers() {
                const identity = this.identity
                const users = this.users

                return this.isClientAdmin ? users : users.filter(user => {
                    return user.id === identity.id
                })
            }
        },

        watch: {
            users() {
                this.subscriptions = {}

                this.users.forEach(user => {
                    this.$set(
                        this.subscriptions,
                        user.id,
                        user.notificationSubscriptions.map(notificationSubscription => {
                            return notificationSubscription.id
                        })
                    )
                })
            }
        },

        mounted() {
            const identity = this.identity

            this.selectedUserId = identity.id
            this.loadUsers()
            this.loadNotificationGroups()
        },

        methods: {
            loadUsers() {
                ClientService.findAll({
                    includes: ['notificationSubscriptions'],
                    sort: [{
                        key: 'role',
                        direction : 'ASC'
                    }]
                }, response => {
                    const data = response.data
                    this.users = data.rows
                })
            },

            loadNotificationGroups() {
                NotificationGroupService.findAll({
                    includes: ['types']
                }, response => {
                    const data = response.data

                    // Remove parent group 'triggerMail'
                    this.notificationGroups = data.rows.filter(group => {
                       return group.slug !== 'triggerMail'
                    }).sort((a,b) => {
                        return b.types.length - a.types.length
                    })
                })
            },

            hasAllNotificationsInGroup(user, group) {
                let hasAllNotifications = true

                const subscriptions = this.subscriptions[user.id]

                group.types.forEach(type => {
                    if (!subscriptions.includes(type.id)) {
                        hasAllNotifications = false
                    }
                })

                return hasAllNotifications
            },

            hasZeroNotificationsInGroup(user, group) {
                const subscriptions = this.subscriptions[user.id]

                let hasNotifications = true

                group.types.forEach(type => {
                    if (subscriptions.includes(type.id)) {
                        hasNotifications = false
                    }
                })

                return hasNotifications
            },

            toggleNotification(user, type) {
                const subscriptions = this.subscriptions[user.id].slice(0)

                // Unsubscribe
                if (subscriptions.includes(type.id)) {
                    if (type.important) {
                        this.$swal({
                            text: $t('profile.notificationCenter.swal.areYouCertainSingle'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                            cancelButtonText: $t('profile.notificationCenter.swal.decline')
                        }).then(() => {
                            subscriptions.splice(subscriptions.indexOf(type.id), 1)
                            MixpanelService.track('Profile - Notifications - Unsubscribe', {type: type.slug})
                            this.updateNotifications(user, subscriptions)
                        }).catch(() => {
                            this.updateNotifications(user, subscriptions)
                        })

                        return
                    }

                    subscriptions.splice(subscriptions.indexOf(type.id), 1)
                    this.updateNotifications(user, subscriptions)
                    MixpanelService.track('Profile - Notifications - Unsubscribe', {type: type.slug})
                    return
                }

                subscriptions.push(type.id)

                // Subscribe
                this.updateNotifications(user, subscriptions)

                MixpanelService.track('Profile - Notifications - Subscribe', {type: type.slug})
            },

            toggleGroup(user, group) {
                const subscriptions = this.subscriptions[user.id].slice(0)

                // Unsubscribe
                if (this.hasAllNotificationsInGroup(user, group)) {
                    const match = group.types.find(type => {
                        return type.important
                    })

                    if (match) {
                        this.$swal({
                            text: $t('profile.notificationCenter.swal.areYouCertainSeveral'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: $t('profile.notificationCenter.swal.confirm'),
                            cancelButtonText: $t('profile.notificationCenter.swal.decline')
                        }).then(() => {
                            group.types.forEach(type => {
                                if (!subscriptions.includes(type.id)) {
                                    return
                                }

                                subscriptions.splice(subscriptions.indexOf(type.id), 1)
                            })

                            this.updateNotifications(user, subscriptions)
                        }).catch(() => {
                            this.updateNotifications(user, subscriptions)
                        })
                        return
                    }

                    group.types.forEach(type => {
                        if (!subscriptions.includes(type.id)) {
                            return
                        }

                        subscriptions.splice(subscriptions.indexOf(type.id), 1)
                    })

                    this.updateNotifications(user, subscriptions)
                    return
                }

                // Subscribe
                group.types.forEach(type => {
                    if (subscriptions.includes(type.id)) {
                        return
                    }

                    subscriptions.push(type.id)
                })

                this.updateNotifications(user, subscriptions)
            },

            updateNotifications(user, subscriptions) {
                const currentSubscriptions = this.subscriptions[user.id]

                // Unsubscribe from notifications
                currentSubscriptions.forEach(notificationId => {
                    if (subscriptions.includes(notificationId)) {
                        return
                    }

                    NotificationService.unsubscribe(user.id, notificationId)
                })

                // Subscribe to notifications
                subscriptions.forEach(notificationId => {
                    if (currentSubscriptions.includes(notificationId)) {
                        return
                    }

                    NotificationService.subscribe(user.id, {typeId: notificationId})
                })

                this.$set(this.subscriptions, user.id, subscriptions)
            }
        },

        components: {
            LogoLoader
        }
    }
</script>
