<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <card>
                        <template v-if="hasLoaded">
                            <form class="form-horizontal">
                                <div class="form-group">
                                    <label class="col-sm-4 control-label">{{$t('profile.email')}}</label>
                                    <div class="col-sm-8">
                                        <p class="form-control-static">{{user.email}}</p>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-sm-4 control-label">{{$t('profile.name')}}</label>
                                    <div class="col-sm-8">
                                        <p class="form-control-static">{{user.name}}</p>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-sm-4 control-label">{{$t('profile.department')}}</label>
                                    <div class="col-sm-8">
                                        <p class="form-control-static">{{user.department.title}}</p>
                                    </div>
                                </div>

                                <language></language>
                            </form>
                        </template>

                        <loader v-else></loader>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Card from '@/app/shared/components/Card'
    import Loader from '@/app/shared/components/Loader'

    import {mapGetters} from 'vuex'

    const EmployeeService = require('@/services/users/EmployeeService')

    import Language from '@/app/users/components/profile/Language'

    export default {
      name: 'EmployeeProfile',
        data() {
            return {
                hasLoaded: false,
                user: null
            }
        },

        computed: mapGetters('identity', {
            identity: 'getIdentity'
          }),

        mounted() {
            this.load();
        },

        methods: {
            load() {
                EmployeeService.find(this.identity.id, {
                    includes: ['department', 'language'],
                }, (response) => {
                    const body = response.data;
                    this.user = body;

                    this.hasLoaded = true;
                })
            }
        },

        components: {
            Card,
            Loader,
            Language
        }
    }
</script>
