<template>
    <div class="list-item">
        <router-link :to="{name: 'backend.users.view', params: {id: user.id}}">
            <div class="item">
                <div class="row">
                    <div class="type-container">
                        <div class="type">
                            <span></span>
                        </div>
                    </div>
                    <div class="content-container">
                        <span>{{user.email}}</span>

                        <div class="additional">
                            <span class="info text-muted last-update">
                                <span class="fa fa-user-plus"></span>
                                {{user.created.date | moment('from', 'now') | capitalize}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>

        <hr/>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_mixins.scss';

    .image-container {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        float: left;
        margin: 0 5px;
    }

    .list-item {
        a {
            color: initial;
        }

        .content-container {
            width: calc(100% - 196px);
            padding-top: 4px;
            padding-left: 20px;
            float: left;
        }

        .type-container {
            float: left;
            padding: 0;

            .type {
                width: 76px;
                position: relative;
				background: #cccccc;

                &:before {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }

        .item {
            padding: 10px 30px;

            .row {
                margin: 0;
				display: flex;
				align-items: center;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.05);
            }

            .additional {
                font-size: 10pt;

                .info {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        &:last-child {
            hr {
                display: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .image-container {
            display: none;
        }

        .favicon {
            display: none;
        }

        .list-item {
            .content-container {
                @include ellipsis;
                width: calc(100% - 76px);
            }

            .last-update {
                display: none;
            }
        }
    }
</style>

<script>
  export default {
    props: {
      user: Object
    },
  }
</script>
