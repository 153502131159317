<template>
    <div class="change-password">
        <div class="row" v-if="!isChanging">
            <div class="col-lg-4">
                <div class="form-group">
                    <label>{{$t('profile.password.label')}}</label>
                    <input type="text" class="form-control password-placeholder" value="********" readonly>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="vertical-align">
                    <button class="btn btn-link" @click="changePassword">
                        {{$t('profile.changePassword')}}
                    </button>
                </div>
            </div>
        </div>

        <div class="row" v-else>
            <div class="col-lg-4">
                <div class="form-group" :class="{'has-error': tooShort}">
                    <label>{{$t('profile.password.label')}}</label>
                    <input type="password" class="form-control" value="" v-model="password">

                    <transition name="slide-fade">
                        <small class="text-danger" v-if="tooShort">{{$t('profile.password.tooShort')}}</small>
                    </transition>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="vertical-align">
                    <button class="btn btn-warning btn-fill btn--push-right" @click="save">
                        <template v-if="!hasLoaded">
                            <span class="fa fa-circle-o-notch fa-spin fa-fw"></span>&nbsp;
                        </template>

                        {{$t('profile.savePassword')}}
                    </button>

                    <button class="btn btn-warning" @click="isChanging = false">
                        {{$t('profile.cancel')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .change-password {
        input.password-placeholder {
            font-size: 30px;
            font-weight: 200;
            -webkit-font-smoothing: antialiased;
            letter-spacing: 5px;
            padding: 7px 15px 0;
        }

        .stars {
            font-size: 30px;
            font-weight: 200;
            -webkit-font-smoothing: antialiased;
            letter-spacing: 5px;
            float: left;
            display: inline-block;
            line-height: 0;
            padding: 0;
            margin: 17px 0 0;
        }

        .badge {
            cursor: pointer;
            margin-left: 15px;
        }

        .btn--push-right {
            margin-right: 10px;
        }
    }
</style>

<script>
    import Vue from 'vue'
    import Loader from '@/app/shared/components/Loader'

    const UserService = require('@/services/users/UserService')

    export default {
        data() {
           return {
               languages: [],
               language: null,

               password: null,
               isChanging: false,
               tooShort: false,

               hasLoaded: true
           }
        },

        watch: {
            password() {
                this.tooShort = false
            }
        },

        methods: {
            changePassword() {
                this.isChanging = true
            },

            save() {
                if (!this.password || this.password.length < 6) {
                    this.tooShort = true
                    return
                }

                this.tooShort = false
                this.hasLoaded = false

                var data = {
                    password: this.password
                }

                UserService.password(this.password, () => {
                    this.password = null
                    this.hasLoaded = true
                    this.isChanging = false
                })
            }
        },

        components: {
            Loader
        }
    }
</script>
