import { render, staticRenderFns } from "./SupportConsent.vue?vue&type=template&id=40a6a182&scoped=true&"
import script from "./SupportConsent.vue?vue&type=script&lang=js&"
export * from "./SupportConsent.vue?vue&type=script&lang=js&"
import style0 from "./SupportConsent.vue?vue&type=style&index=0&id=40a6a182&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a6a182",
  null
  
)

export default component.exports